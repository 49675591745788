import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from 'src/store';

const Home = () => {
  const authToken = useSelector(
    (globalState: RootState) => globalState.auth.token
  );

  return (
    <>
      <div
        className="absolute inset-x-0 -top-40 z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#0044CC] to-[#4719FF] opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>

      <div className="relative isolate px-6 pt-14 lg:px-8">
        <div className="mx-auto max-w-4xl py-16 sm:py-32">
          <div className="text-center">
            <h1 className="text-4xl font-bold tracking-tight text-[#0044CC] sm:text-6xl">
              Everything you need in one place
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Lionshare is the all-in-one solution that effortlessly connects
              you with our team on any device. With Lionshare, you can enjoy a
              streamlined request system, efficient project management,
              real-time notifications, access to resources, and much more.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <Link
                to={authToken ? '/dashboard' : '/login'}
                className="rounded-full bg-[#0044CC] px-6 py-2 text-sm text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                {authToken ? 'Go to Dashboard' : 'Access Now'}{' '}
                <span aria-hidden="true">→</span>
              </Link>
            </div>
            <div className="pt-8">
              <img
                src="/assets/dash-ls.png"
                alt="dashboard"
                className="w-full"
              />
            </div>
          </div>
          <div id="about" className="pt-16">
            <span className="rounded-full bg-[#0044CC] px-4 py-1 text-sm text-white shadow-sm">
              About
            </span>
            <div className="flex flex-col pt-8 sm:flex-row sm:items-center">
              <h2 className="text-2xl font-bold tracking-tight text-[#0044CC] sm:text-4xl">
                All-in-one solution
              </h2>
              <p className="mt-6 w-full max-w-2xl leading-6 text-gray-600">
                Streamline your projects with our comprehensive all-in-one
                solution. Our platform combines task management, team
                collaboration, document sharing, and progress tracking in a
                single dashboard. Stay organized, boost productivity, and
                achieve success with our feature-rich project management tool.
              </p>
            </div>
          </div>
        </div>

        <div
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          aria-hidden="true"
        >
          <div className="relative aspect-square w-full  bg-gradient-to-tr from-[#4719FF] to-[#0044CC] opacity-10 " />
        </div>
      </div>

      <div
        id="features"
        className="relative w-full bg-white px-6 py-16 sm:py-20 lg:px-8"
      >
        <div className="mx-auto max-w-4xl">
          <span className="rounded-full bg-[#0044CC] px-4 py-1 text-sm text-white shadow-sm">
            Features
          </span>
          <div className="space-y-12 pt-4 text-center sm:space-y-32 sm:pt-12 sm:text-left">
            <div className="flex flex-col-reverse items-center md:flex-row">
              <img
                src="/assets/responsive-interface.png"
                alt="responsive interface"
                className="w-full max-w-[500px] sm:pr-6"
              />
              <div className="pb-8 md:pb-0">
                <h3 className="text-2xl font-bold tracking-tight text-[#0044CC] sm:text-4xl">
                  Your experience, your way
                </h3>
                <p className="mt-6 w-full max-w-2xl leading-6 text-gray-600">
                  Our software features an adaptable interface, ensuring smooth
                  navigation across devices and screen sizes.
                </p>
              </div>
            </div>

            <div className="flex flex-col items-center md:flex-row">
              <div className="pb-8 md:pb-0">
                <h3 className="text-2xl font-bold tracking-tight text-[#0044CC] sm:text-4xl">
                  Data at your fingertips
                </h3>
                <p className="mt-6 w-full max-w-2xl leading-6 text-gray-600">
                  Accessing data and tracking projects becomes a breeze with our
                  intuitive interfaces.
                </p>
              </div>
              <img
                src="/assets/intuitive-interface.png"
                alt="responsive interface"
                className="w-full max-w-[500px] sm:pl-6"
              />
            </div>

            <div className="flex flex-col-reverse items-center md:flex-row">
              <img
                src="/assets/simple-interface.png"
                alt="responsive interface"
                className="w-full max-w-[500px] sm:pr-6"
              />
              <div className="pb-8 md:pb-0">
                <h3 className="text-2xl font-bold tracking-tight text-[#0044CC] sm:text-4xl">
                  Stay focused on what counts
                </h3>
                <p className="mt-6 w-full max-w-2xl leading-6 text-gray-600">
                  Our simplified interfaces keep distractions at bay, enabling
                  you to concentrate on what truly matters.
                </p>
              </div>
            </div>

            <div className="flex flex-col items-center md:flex-row">
              <div className="pb-8 md:pb-0">
                <h3 className="text-2xl font-bold tracking-tight text-[#0044CC] sm:text-4xl">
                  Lionshare on-the-go
                </h3>
                <p className="mt-6 w-full max-w-2xl leading-6 text-gray-600">
                  Embrace a seamless mobile browsing experience with ease
                  through our user-friendly design. Navigate effortlessly,
                  anytime and anywhere.
                </p>
              </div>
              <img
                src="/assets/mobile-friendly.png"
                alt="responsive interface"
                className="w-full max-w-[500px] sm:pl-6"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="relative flex w-full items-center bg-[#0044CC] px-6 py-6 lg:px-8">
        <div className="mx-auto flex w-full max-w-4xl flex-col items-center justify-around space-x-2 space-y-4  sm:flex-row sm:space-y-0">
          <h3 className="text-2xl tracking-tight text-white sm:text-3xl">
            Let's get started
          </h3>

          <img
            src="/assets/Arrow.png"
            alt="arrow"
            className="hidden max-w-[150px] sm:block"
          />

          <Link
            to="/login"
            className="rounded-full bg-white px-6 py-2 text-sm text-[#0044CC] shadow-sm hover:bg-neutral-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Access now <span aria-hidden="true">→</span>
          </Link>
        </div>
      </div>

      <div className="relative flex w-full items-center bg-white px-6 py-4 lg:px-8">
        <div className="mx-auto flex w-full max-w-2xl items-center justify-center space-x-2">
          <span aria-hidden="true" className="text-[12px] opacity-50">
            Copyright © {new Date().getFullYear()}{' '}
          </span>
        </div>
      </div>
    </>
  );
};

export default Home;
