import { FC, useEffect, useState } from 'react';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import GlobalSearch from 'src/components/GlobalSearch';
import CompanySelector from 'src/components/CompanySelector';
import BellNotification from 'src/components/BellNotification';
import ENVIRONMENT from 'src/utils/environments';
import { useAppTour } from 'src/context/appTourContext';
import NavbarBrand from './NavbarBrand';
import NavbarMenu from './NavbarMenu';
import NewTaskButton from '../NewTaskButton';

interface NavBarProps {
  hidePendingNavItem: boolean;
  onInvite: () => void;
  onResetPassword: () => void;
}

const Navbar: FC<NavBarProps> = ({
  hidePendingNavItem,
  onInvite,
  onResetPassword,
}) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [openedTour, setOpenedTour] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const breakpoints = useBreakpoint(true);
  const { setOpenTour, setStepTarget, unsetStepTarget, subscribe } =
    useAppTour();

  useEffect(() => {
    return subscribe((current) => {
      if (current === null) {
        setOpenedTour(false);
        return;
      }

      setOpenedTour(true);

      if (
        [4, 5].includes(current) ||
        (!breakpoints.md && current === 0) ||
        (!breakpoints.xl && [2, 3].includes(current))
      ) {
        setOpenMenu(true);
        return;
      }

      setOpenMenu(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [breakpoints.md, breakpoints.xl]);

  useEffect(() => {
    if (!openMenu) {
      unsetStepTarget(4);
      unsetStepTarget(5);

      if (!breakpoints.md) {
        unsetStepTarget(0);
      }

      if (!breakpoints.xl) {
        unsetStepTarget(2);
        unsetStepTarget(3);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openMenu, breakpoints.md, breakpoints.xl]);

  return (
    <div className="sticky left-0 top-0 z-10 mt-0 w-full bg-[#0044CC] pt-0 shadow-sm">
      {ENVIRONMENT.IS_PRODUCTION ? undefined : (
        <div className="bg-orange-400 py-1 text-center text-sm text-white">
          Development
        </div>
      )}

      <div className="mx-auto flex h-16 w-full max-w-[1920px] items-center gap-4 px-4 sm:px-6 lg:px-8">
        <Link className="flex-none" to="/dashboard">
          <NavbarBrand />
        </Link>

        <div className="flex flex-1 items-center justify-end gap-4">
          {breakpoints.md && (
            <div
              className="w-full max-w-[520px]"
              ref={(node) => setStepTarget(0, node)}
            >
              <GlobalSearch dropdownRenderClassName="min-w-[360px] w-[calc(100%+120px)]" />
            </div>
          )}

          <NewTaskButton onClick={() => setOpenMenu(false)} />

          {breakpoints.md && (
            <CompanySelector
              className="max-w-[280px]"
              customerLabel="abbreviation"
            />
          )}

          <div ref={(node) => setStepTarget(1, node)}>
            <BellNotification />
          </div>

          <NavbarMenu
            open={openMenu}
            breakpoints={breakpoints}
            hidePendingItem={hidePendingNavItem}
            onOpenChange={(value) => {
              if (!openedTour) {
                setOpenMenu(value);
              }
            }}
            onInvite={onInvite}
            onResetPassword={onResetPassword}
            onStartTour={() => {
              if (location.pathname !== '/dashboard') {
                navigate('/dashboard');
              }
              setOpenTour(true);
            }}
            setStepTarget={setStepTarget}
          />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
