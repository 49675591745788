import React from 'react';
import { Link } from 'react-router-dom';
import UserCard from 'src/components/UserCard';
import Box from 'src/components/Box';
interface User {
  name: string;
  role: string;
  snippet: string;
  link: string;
  imageUrl: string;
}

const users: User[] = [
  {
    name: 'Andrew Sierra',
    role: 'Video Production Manager',
    snippet:
      'Are you looking to create captivating scripts, deliver impactful video content, and craft unforgettable stories?',
    link: 'https://calendar.app.google/uNSgw2RAXrv7N9L96',
    imageUrl: '/assets/Andrew.png',
  },
  {
    name: 'Christopher Savage',
    role: 'Founder / CEO',
    snippet:
      'Are you looking to boost your revenue by incorporating new services or leveraging industry-level experience to create marketing plans that will outshine your competition?',
    link: 'https://calendar.app.google/rw4nYKqsAHKoFUQWA',
    imageUrl: '/assets/Chris.png',
  },
  {
    name: 'Francisco Cordeiro',
    role: 'Ad Manager',
    snippet:
      'Are you seeking to create impactful ad campaigns, leverage analytics, and maximize ROI by optimizing performance across multiple platforms to effectively reach your target audience?',
    link: 'https://calendar.app.google/yYJzf2u5JnaN8NnC7',
    imageUrl: '/assets/Francisco.png',
  },
  {
    name: 'Santiago Sanches',
    role: 'CTO',
    snippet:
      'Are you looking to solve unique challenges, streamline development processes, and implement scalable solutions that foster growth and competitiveness in the digital landscape?',
    link: 'https://calendar.app.google/vo11updE8Nj9TKvR7',
    imageUrl: '/assets/Santi.png',
  },
];

interface BookingProps {
  showHomeButton?: boolean;
}

const Booking: React.FC<BookingProps> = ({ showHomeButton = false }) => {
  return (
    <Box
      className={`mx-auto max-w-7xl ${
        showHomeButton ? 'px-10 pb-20 pt-10' : 'p-6'
      }`}
      noPadding
    >
      {showHomeButton && (
        <div className="mb-8">
          <Link
            to="/"
            className="rounded-full bg-[#0044CC] px-6 py-2 text-sm text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Go to Lionshare
          </Link>
        </div>
      )}

      <div className="grid gap-x-8 gap-y-20 2xl:grid-cols-3">
        <div className="max-w-4xl">
          <img
            src="/assets/logo-sgm3.png"
            alt="Savage logo "
            className="w-full max-w-[320px] pb-12"
          />
          <h1 className="pb-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Meet your project managers
          </h1>
          <h2 className="tracking-tight text-gray-500 sm:text-2xl">
            Discover the Force Behind Our Achievements!
          </h2>
          <p className="mt-8 text-lg leading-8 text-gray-600">
            Book a call with our dynamic team of passionate professionals. With
            diverse expertise and a shared commitment to excellence, we
            collaborate to bring innovative solutions to life, exceeding
            expectations and driving success.
          </p>
        </div>

        <ul className="grid gap-x-8 gap-y-12 md:grid-cols-2 md:gap-y-16 2xl:col-span-2">
          {users.map((user) => (
            <li key={user.name}>
              <UserCard
                name={user.name}
                role={user.role}
                snippet={user.snippet}
                link={user.link}
                imageUrl={user.imageUrl}
              />
            </li>
          ))}
        </ul>
      </div>
    </Box>
  );
};

export default Booking;
