import { ReactNode, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import Toast from 'src/components/Toast';
import WorkLogBalanceCard from 'src/components/WorkLogBalanceCard';
import SmsSignUpModal from 'src/components/SmsSignUpModal';
import InviteModal from 'src/components/InviteModal';
import ResetPasswordModal from 'src/components/ResetPasswordModal';
import TaskDrawer from 'src/components/TaskDrawer';
import { RootState } from 'src/store';
import { getPendingForms } from 'src/store/pendingForms/reducer';
import { getPendingTasks } from 'src/store/pendingTasks/reducer';
import { getPendingInvites } from 'src/store/pendingInvites/reducer';
import { getContacts } from 'src/store/contacts/reducer';
import { getInbox } from 'src/store/inbox/reducer';
import { getQueryValue } from 'src/utils/stringHelpers';
import ENVIRONMENT from 'src/utils/environments';
import { UserResponse } from 'src/types';
import Navbar from './Navbar';
import NewTaskButton from './NewTaskButton';
import MenuLeft from './MenuLeft';
import AdCarousel from './AdCarousel';
import MenuRightVideo from './MenuRightVideo';

const version = process.env.REACT_APP_GIT_HASH
  ? process.env.REACT_APP_GIT_HASH.substring(0, 6)
  : null;

export default function Layout({ children }: { children?: ReactNode }) {
  const [resetModal, setResetModal] = useState(false);
  const [inviteModal, setInviteModal] = useState(false);

  const { pathname, search } = useLocation();

  const user: UserResponse = useSelector(
    (globalState: any) => globalState.auth.user
  );
  const pendingTasks = useSelector(
    (globalState: RootState) => globalState.pendingTasks
  );
  const pendingForms = useSelector(
    (globalState: RootState) => globalState.pendingForms
  );

  const taskId = getQueryValue(search, 'task_id');

  const breakpoints = useBreakpoint(true);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const showToast =
    !pathname.includes('pending') &&
    (!!pendingTasks.data?.data?.length || !!pendingForms.data?.data?.length);

  const hidePendingNavItem =
    !pendingTasks.data?.data?.length && !pendingForms.data?.data?.length;

  const hideSeeDetail = pathname === '/retainer-summary';

  const closeTask = () => {
    navigate(`${pathname}`, { preventScrollReset: true });
  };

  useEffect(() => {
    dispatch(getPendingForms());
    dispatch(getPendingTasks());
    dispatch(getInbox());
  }, [dispatch]);

  return (
    <div className="relative flex min-h-[100vh] flex-col">
      <TaskDrawer open={taskId} onClose={closeTask} />

      <ResetPasswordModal
        visible={resetModal}
        onClose={() => setResetModal(false)}
      />

      <InviteModal
        visible={inviteModal}
        onInviteSent={() => {
          dispatch(getContacts());
          dispatch(getPendingInvites());
        }}
        onClose={() => setInviteModal(false)}
      />

      {user.sms_notifications === null ? (
        <SmsSignUpModal user={user} />
      ) : undefined}

      <Navbar
        hidePendingNavItem={hidePendingNavItem}
        onInvite={() => setInviteModal(true)}
        onResetPassword={() => setResetModal(true)}
      />

      <div className="mx-auto w-full max-w-[1920px] flex-1 px-6 pt-4 xl:pt-0">
        <div className="flex flex-wrap gap-6">
          {breakpoints.xl ? (
            <div className="relative w-[280px]">
              <div
                className={`sticky top-0 flex flex-col justify-between ${
                  ENVIRONMENT.IS_PRODUCTION
                    ? `top-16 ${
                        showToast
                          ? 'min-h-[calc(100vh-140px)]'
                          : 'min-h-[calc(100vh-75px)]'
                      }`
                    : `top-[92px] ${
                        showToast
                          ? 'min-h-[calc(100vh-170px)]'
                          : 'min-h-[calc(100vh-105px)]'
                      }`
                }`}
              >
                <MenuLeft
                  hidePendingItem={hidePendingNavItem}
                  onInvite={() => setInviteModal(true)}
                />

                <div
                  className={`hidden pt-2 text-xs text-gray-300 xl:block ${
                    showToast ? '-mb-16' : ''
                  }`}
                >
                  {version ? `Ver: ${version}` : undefined}
                </div>
              </div>
            </div>
          ) : undefined}

          <div className="order-3 flex-1 basis-full pb-6 xl:order-none xl:basis-0 xl:pt-4">
            {children}
          </div>

          <div className="order-1 flex flex-1 flex-wrap items-start justify-between gap-6 xl:order-none xl:min-w-[160px] xl:max-w-[210px] xl:flex-none xl:flex-col">
            {!ENVIRONMENT.IS_PRODUCTION ? (
              <div
                className={`flex w-full flex-row-reverse flex-wrap gap-6 xl:sticky ${
                  ENVIRONMENT.IS_PRODUCTION ? 'top-24' : 'top-28'
                }`}
              >
                <AdCarousel containerClassName="w-full flex-none md:w-1/3 md:flex-auto xl:w-full xl:flex-none" />

                <div className="flex-1">
                  <WorkLogBalanceCard hideSeeDetail={hideSeeDetail} />
                </div>
              </div>
            ) : (
              <MenuRightVideo />
            )}
          </div>
        </div>
      </div>

      <div className="absolute bottom-1 left-6 text-xs text-gray-300 xl:hidden">
        {version ? `Ver: ${version}` : undefined}
      </div>

      <div className="fixed bottom-6 right-3 z-50 hidden xl:block">
        <NewTaskButton />
      </div>

      {showToast && <Toast />}
    </div>
  );
}
