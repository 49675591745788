import { FC, useRef, useState } from 'react';
import { Button, Checkbox, Popover, Radio, Space, Tooltip } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { updateAuthData } from 'src/store/auth/reducer';
import apiRequests from 'src/utils/api';
import apiRoutes from 'src/utils/apiRoutes';
import asyncErrorHandler from 'src/utils/asyncErrorHandler';
import SmsSignUpModal from '../SmsSignUpModal';

export type NotificationStatus = 'all' | 'read' | 'unread';

type TimoutAlert = {
  email?: ReturnType<typeof setTimeout>;
  sms?: ReturnType<typeof setTimeout>;
  push?: ReturnType<typeof setTimeout>;
};

interface DropdownFooterProps {
  notificationStatus: NotificationStatus;
  onNotificationStatusChange: (item: NotificationStatus) => void;
}

const DropdownFooter: FC<DropdownFooterProps> = ({
  notificationStatus,
  onNotificationStatusChange,
}) => {
  const user = useSelector((globalState: RootState) => globalState.auth.user);
  const [smsModalOpen, setSmsModalOpen] = useState(false);
  const [smsAlertOpen, setSmsAlertOpen] = useState(false);
  const [emailAlertOpen, setEmailAlertOpen] = useState(false);
  const [pushAlertOpen, setPushAlertOpen] = useState(false);
  const timeoutAlertRef = useRef<TimoutAlert>({});

  const dispatch = useDispatch();

  const updateUser = async (data: any) => {
    try {
      await apiRequests.put(
        `${apiRoutes.CONTACTS}/${user?.contact?.uuid}`,
        data
      );
    } catch (error) {
      asyncErrorHandler(error);
    }
  };

  const onPermissionChange = (event: CheckboxChangeEvent) => {
    const data = { [event.target.name ?? '']: event.target.checked };
    const newUser = {
      ...user,
      ...data,
    };

    if (!newUser.email_notifications && !newUser.sms_notifications) {
      if (event.target.name === 'sms_notifications') {
        clearTimeout(timeoutAlertRef.current.sms);
        setSmsAlertOpen(true);
        timeoutAlertRef.current.sms = setTimeout(() => {
          setSmsAlertOpen(false);
        }, 2000);
      }

      if (event.target.name === 'email_notifications') {
        clearTimeout(timeoutAlertRef.current.email);
        setEmailAlertOpen(true);
        timeoutAlertRef.current.email = setTimeout(() => {
          setEmailAlertOpen(false);
        }, 2000);
      }

      return;
    }

    if (smsAlertOpen) {
      setSmsAlertOpen(false);
    }

    if (emailAlertOpen) {
      setEmailAlertOpen(false);
    }

    if (pushAlertOpen) {
      setPushAlertOpen(false);
    }

    if (
      newUser.push_notifications &&
      event.target.name === 'push_notifications'
    ) {
      if (localStorage) {
        localStorage.removeItem('showNotificationPermission');
      }

      if ('Notification' in window && Notification.permission === 'denied') {
        clearTimeout(timeoutAlertRef.current.push);
        setPushAlertOpen(true);
        timeoutAlertRef.current.push = setTimeout(() => {
          setPushAlertOpen(false);
        }, 2000);
      }
    }

    if (
      newUser.sms_notifications &&
      event.target.name === 'sms_notifications'
    ) {
      setSmsModalOpen(true);
      return;
    }

    dispatch(updateAuthData(newUser));

    updateUser(data);
  };

  const onPopoverChannelsOpen = (open: boolean) => {
    if (!open) {
      setEmailAlertOpen(false);
      setSmsAlertOpen(false);
      setPushAlertOpen(false);
      return;
    }

    if (
      user?.push_notifications &&
      'Notification' in window &&
      Notification.permission === 'denied'
    ) {
      clearTimeout(timeoutAlertRef.current.push);
      setPushAlertOpen(true);
      timeoutAlertRef.current.push = setTimeout(() => {
        setPushAlertOpen(false);
      }, 2000);
    }
  };

  return (
    <div className="bl-dropdown-footer">
      {smsModalOpen && user && (
        <SmsSignUpModal user={user} onClose={() => setSmsModalOpen(false)} />
      )}

      <Radio.Group
        options={[
          { label: 'All', value: 'all' },
          { label: 'Unread', value: 'unread' },
          { label: 'Read', value: 'read' },
        ]}
        onChange={({ target }) => onNotificationStatusChange(target.value)}
        value={notificationStatus}
        optionType="button"
        buttonStyle="solid"
        size="small"
      />

      <Popover
        title="Notifications channels"
        placement="leftTop"
        trigger="click"
        overlayClassName="bl-dropdown-inside-popover"
        destroyTooltipOnHide
        onOpenChange={onPopoverChannelsOpen}
        content={
          <Space direction="vertical">
            <Checkbox
              name="email_notifications"
              checked={user?.email_notifications ?? false}
              onChange={onPermissionChange}
            >
              <Tooltip
                open={emailAlertOpen}
                placement="right"
                title="You need to have at least SMS or email notification enabled"
                overlayStyle={{ maxWidth: 180 }}
              >
                Email
              </Tooltip>
            </Checkbox>

            <Checkbox
              name="sms_notifications"
              checked={user?.sms_notifications ?? false}
              onChange={onPermissionChange}
            >
              <Tooltip
                open={smsAlertOpen}
                placement="right"
                title="You need to have at least SMS or email notification enabled"
                overlayStyle={{ maxWidth: 180 }}
              >
                SMS
              </Tooltip>
            </Checkbox>

            <Checkbox
              name="push_notifications"
              checked={user?.push_notifications ?? false}
              onChange={onPermissionChange}
            >
              <Tooltip
                open={pushAlertOpen}
                placement="right"
                title="Permission denied, please enable the notification permission on this browser"
                overlayStyle={{ maxWidth: 180 }}
              >
                Push
              </Tooltip>
            </Checkbox>
          </Space>
        }
      >
        <Button
          type="link"
          size="small"
          icon={<SettingOutlined style={{ fontSize: 24 }} />}
        />
      </Popover>
    </div>
  );
};

export default DropdownFooter;
