/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useRef, useState } from 'react';
import { Form, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import axios, { AxiosError } from 'axios';
import ProjectContext from 'src/context/project';
import { TaskObjectResponse, UploadFileApi } from 'src/types';
import apiRequests from 'src/utils/api';
import apiRoutes from 'src/utils/apiRoutes';
import asyncErrorHandler from 'src/utils/asyncErrorHandler';
import { getQueryValue } from 'src/utils/stringHelpers';
import ENVIRONMENT from 'src/utils/environments';
import { RichTextEditorHandle } from 'src/components/RichTextEditor';
import { RootState } from 'src/store';
import { getPendingTasks } from 'src/store/pendingTasks/reducer';
import { getDashboardTasks } from 'src/store/dashboardTasks/reducer';

const useTaskDrawer = () => {
  const [state, setState] = useState({
    loading: false,
    data: null as null | TaskObjectResponse,
    axiosError: null as AxiosError | null,
    saving: false,
    showUploadModal: false,
    approval_status: undefined as any,
    showFeedbackModal: null as any,
  });

  const [uploads, setUploads] = useState<UploadFileApi[]>([]);

  const projectRetainer = useSelector(
    (globalState: RootState) => globalState.projectRetainer
  );

  const editorRef = useRef<RichTextEditorHandle>(null);

  const { pathname, search } = useLocation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const taskId = getQueryValue(search, 'task_id');
  const isCreateTask = taskId === 'create';
  const projectContext: any = useContext(ProjectContext);

  const fetchTasks = () => {
    if (pathname.startsWith('/pending')) {
      dispatch(getPendingTasks());
      return;
    }

    const projectRetainerUuid = projectRetainer.data?.uuid;

    if (projectRetainerUuid && pathname.startsWith('/dashboard')) {
      dispatch(
        getDashboardTasks({
          'filters[project.uuid][]': projectRetainerUuid,
        })
      );
    }
  };

  const toggleUploadModal = () => {
    setState((prevState) => ({
      ...prevState,
      showUploadModal: !prevState.showUploadModal,
    }));
  };

  const handleSave = async (data?: any) => {
    try {
      setState((prevState) => ({ ...prevState, saving: true }));
      const formData = data ? {} : await form.validateFields();

      formData.status = isCreateTask ? 'todo' : formData.status;

      if (isCreateTask) {
        formData.project_id = projectRetainer.data?.uuid;

        formData.description_delta = editorRef.current?.getYjs();

        formData.uploads = uploads
          .filter(
            (item) => item.response?.uuid && item.response?.stated === false
          )
          .map((el: UploadFileApi) => el.response?.uuid);
      } else {
        delete formData.description;
      }

      const response = isCreateTask
        ? await apiRequests.post(`${apiRoutes.PROJECT_TASKS}`, data ?? formData)
        : await apiRequests.put(
            `${apiRoutes.PROJECT_TASKS}/${taskId}`,
            data ?? formData
          );

      setState((prevState) => ({ ...prevState, saving: false }));

      if (!data) {
        if (isCreateTask) {
          message.success('Task created.');
        } else {
          message.success('Task updated.');
        }

        if (typeof projectContext?.onUpdateTrigger === 'function') {
          projectContext?.onUpdateTrigger();
        }
      }

      return response;
    } catch (error) {
      setState((prevState) => ({ ...prevState, saving: false }));
      asyncErrorHandler(error);
    }
  };

  const downloadAllAssets = async () => {
    try {
      setState((prevState) => ({ ...prevState, saving: true }));
      const res = await apiRequests.post(`${apiRoutes.MASS_DOWNLOAD}`, {
        full_download: true,
        task_ids: [taskId],
      });
      message.success(res?.data?.message);
      setState((prevState) => ({ ...prevState, saving: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, saving: false }));
      asyncErrorHandler(error);
    }
  };

  const handleUpdateData = async () => {
    try {
      setState((prevState) => ({ ...prevState, saving: true }));
      const res = await apiRequests.get(`${apiRoutes.PROJECT_TASKS}/${taskId}`);
      setState((prevState) => ({
        ...prevState,
        saving: false,
        data: res?.data?.data,
      }));
    } catch (error) {
      asyncErrorHandler(error);
      setState((prevState) => ({
        ...prevState,
        saving: false,
      }));
    }
  };

  const resetForm = () => {
    form.resetFields();
    setState((prevState) => ({
      ...prevState,
      data: null,
      filesData: [],
      loading: false,
      axiosError: null,
      approval_status: undefined,
      showFeedbackModal: false,
      showUploadModal: false,
    }));

    setUploads([]);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setState((prevState) => ({
          ...prevState,
          loading: true,
          axiosError: null,
        }));

        const res = await apiRequests.get(
          `${apiRoutes.PROJECT_TASKS}/${taskId}`
        );

        const projectRes: TaskObjectResponse = res?.data?.data;

        setState((prevState) => ({
          ...prevState,
          loading: false,
          data: projectRes,
        }));

        const commentFiles = projectRes.comment_uploads.reduce(
          (acc: any, item: any) => [...acc, ...item.uploads],
          []
        );

        setUploads(
          [...projectRes.uploads, ...commentFiles].map((upload) => ({
            uid: upload.uuid,
            name: upload.name,
            url: `${ENVIRONMENT.REACT_APP_UPLOADS_PATH}/${upload.uuid}.${upload.extension}`,
            status: 'done',
            extension: upload.extension,
            size: Number(upload.size),
            response: {
              uuid: upload.uuid,
              url: `${ENVIRONMENT.REACT_APP_UPLOADS_PATH}/${upload.uuid}.${upload.extension}`,
              stated: true,
            },
          }))
        );

        form.setFieldsValue({
          ...projectRes,
          project_id: projectRes?.project?.uuid,
          description: null,
        });
      } catch (error) {
        if (axios.isAxiosError(error)) {
          const axiosError = error as AxiosError;

          setState((old) => ({
            ...old,
            axiosError: axiosError,
            loading: false,
          }));

          if (axiosError.response?.status !== 404) {
            asyncErrorHandler(error);
          }
        } else {
          asyncErrorHandler(error);
        }
      }
    };

    if (taskId && !isCreateTask) {
      fetchData();
    }
  }, [taskId]);

  return {
    form,
    state,
    uploads,
    isCreateTask,
    editorRef,
    handleSave,
    setUploads,
    toggleUploadModal,
    handleUpdateData,
    downloadAllAssets,
    setState,
    resetForm,
    fetchTasks,
  };
};

export default useTaskDrawer;
