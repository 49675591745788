import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import Task from 'src/components/Task';
import { RootState } from 'src/store';
import {
  getDashboardTasks,
  clearDashboardTasksData,
  setDashboardTaskCompleted,
  updateDashboardTaskCommentRelatedUserMention,
} from 'src/store/dashboardTasks/reducer';
import { useAppTour } from 'src/context/appTourContext';
import { UserResponse } from 'src/types';

export default function Dashboard() {
  const user: UserResponse = useSelector(
    (globalState: any) => globalState.auth.user
  );
  const dashboardTasks = useSelector(
    (globalState: RootState) => globalState.dashboardTasks
  );
  const projectRetainer = useSelector(
    (globalState: RootState) => globalState.projectRetainer
  );

  const projectRetainerUuid = projectRetainer.data?.uuid;

  const dispatch = useDispatch();
  const { setStepTarget } = useAppTour();

  const taskItems = useMemo(() => {
    return dashboardTasks.data?.data?.filter(
      (task) => task?.customer_uuid === user?.contact?.customer?.uuid
    );
  }, [dashboardTasks.data?.data, user?.contact?.customer?.uuid]);

  const fetchTasks = useCallback(() => {
    if (projectRetainerUuid) {
      dispatch(
        getDashboardTasks({
          'filters[project.uuid][]': projectRetainerUuid,
        })
      );
    }
  }, [dispatch, projectRetainerUuid]);

  useEffect(() => {
    fetchTasks();
  }, [fetchTasks]);

  return (
    <div ref={(node) => setStepTarget(6, node)}>
      <Task
        loading={
          (projectRetainer.loading && !projectRetainerUuid) ||
          (dashboardTasks.loading && !dashboardTasks.data)
        }
        data={taskItems}
        showCompletedValue={dashboardTasks.showCompleted}
        onShowCompleted={(checked) => {
          dispatch(clearDashboardTasksData());
          dispatch(setDashboardTaskCompleted(checked));
          fetchTasks();
        }}
        onMarkAsRead={(uuid, user) => {
          dispatch(
            updateDashboardTaskCommentRelatedUserMention({
              user,
              uuid,
              newValues: { read_at: dayjs().format() },
            })
          );
        }}
      />
    </div>
  );
}
