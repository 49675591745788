import { FC } from 'react';
import { useStopwatch, useTimer } from 'react-timer-hook';
import { getDateDifferenceInMinutes } from 'src/utils/stringHelpers';

interface ApprovalCountDownProps {
  approval_target?: any;
}

const ApprovalCountDown: FC<ApprovalCountDownProps> = ({ approval_target }) => {
  const target = new Date(approval_target);

  const offsetTimestamp = new Date();
  offsetTimestamp.setMinutes(
    offsetTimestamp.getMinutes() + getDateDifferenceInMinutes(target.toString())
  );

  const timer = useTimer({
    expiryTimestamp: target,
    autoStart: true,
  });
  const time = useStopwatch({
    offsetTimestamp,
    autoStart: true,
  });

  const isExceeded = target < new Date(Date.now());

  const days = time?.days || 1;

  return (
    <div className="w-full max-w-[180px] rounded-md bg-[#F5222D] py-1 text-center text-white sm:max-w-[220px]">
      <div className="text-xl leading-none">
        {isExceeded
          ? `You are ${days} day${days > 1 ? 's' : ''} late`
          : `${timer?.hours + timer.days * 24}:${
              timer?.minutes < 10 ? '0' : ''
            }${timer?.minutes} Left`}
      </div>

      <div className="text-xs">on your approval</div>
    </div>
  );
};

export default ApprovalCountDown;
