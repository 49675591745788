import { FC, useMemo, useState } from 'react';
import {
  Button,
  Drawer,
  Form,
  Input,
  Skeleton,
  message,
  Modal,
  Avatar,
} from 'antd';
import { CheckOutlined, SwapRightOutlined } from '@ant-design/icons';
import { IoCalendarClearOutline } from 'react-icons/io5';
import Comment from 'src/components/Comment';
import StatusActions from 'src/components/StatusActions';
import FeedbackModal from 'src/components/FeedbackModal';
import ApprovalCountDown from 'src/components/ApprovalCountdown';
import UploadDragger from 'src/components/FileUpload/UploadDragger';
import RichTextEditorForm from 'src/components/RichTextEditor/RichTextEditorForm';
import AxiosErrorResult from 'src/components/AxiosErrorResult';
import UserAvatar from 'src/components/UserAvatar';
import TagDropdown from 'src/components/TagDropdown';
import StatusTag from 'src/components/StatusTag';
import apiRoutes from 'src/utils/apiRoutes';
import { parseDateToUSFormat, removeImage } from 'src/utils/stringHelpers';
import getTaskStatusOptions from 'src/utils/getTaskStatusOptions';
import useTaskDrawer from './useTaskDrawer';

interface TaskDrawerProps {
  open: any;
  onClose: any;
}

const TaskDrawer: FC<TaskDrawerProps> = ({ open, onClose }) => {
  const {
    state,
    uploads,
    form,
    isCreateTask,
    editorRef,
    setState,
    handleSave,
    setUploads,
    downloadAllAssets,
    resetForm,
    fetchTasks,
  } = useTaskDrawer();
  const { loading, data, saving, axiosError } = state;

  const [enableDrawerKeyboard, setEnableDrawerKeyboard] = useState(true);
  const [hasFileLoading, setHasFileLoading] = useState(false);
  const [loadingImages, setLoadingImages] = useState(0);

  const showConfirmCloseTask = () => {
    Modal.confirm({
      title: 'Do you want to close thi task?',
      content:
        'You still have uploads pending, if you close this task now any changes or uploads made will be lost.',
      onOk() {
        onClose();
        uploads.map((item) => item.abortController?.abort());
        setHasFileLoading(false);
      },
      okText: 'Yes, cancel it',
      okType: 'danger',
      cancelText: 'No',
    });
  };

  const safeCloseTask = () => {
    if (!isCreateTask && hasFileLoading) {
      showConfirmCloseTask();
    } else {
      onClose();
    }
  };

  const optionsStatus = useMemo(() => {
    return getTaskStatusOptions(data?.status, !!data?.approval_target);
  }, [data?.status, data?.approval_target]);

  const responsible = data?.related_users?.find(
    (item: any) => item.pivot.type === 'responsible'
  );

  const followers =
    data?.related_users?.filter(
      (item: any) => item.pivot.type === 'follower'
    ) ?? [];

  return (
    <Drawer
      open={!!open}
      title={
        <div className="flex items-center justify-between gap-6">
          <span>
            {axiosError ? 'Task not found' : ''}
            {isCreateTask && !axiosError ? 'Create new task' : ''}
            {!isCreateTask && !axiosError ? 'Task details' : ''}
          </span>

          {data?.approval_target && (
            <ApprovalCountDown approval_target={data.approval_target} />
          )}
        </div>
      }
      className="task-drawer"
      width={600}
      headerStyle={{ background: '#F3F5FD', padding: '8px 24px' }}
      bodyStyle={{ paddingBottom: 12, paddingTop: 12, background: '#F3F5FD' }}
      footerStyle={{ background: '#F3F5FD' }}
      keyboard={enableDrawerKeyboard}
      // closeIcon={<CloseOutlined className="text-2xl" />}
      onClose={safeCloseTask}
      afterOpenChange={(opened) => {
        if (!opened) {
          resetForm();
        }
      }}
      destroyOnClose
      extra={
        isCreateTask ? (
          <div className="space-x-4">
            <Button onClick={onClose} type="link">
              Cancel
            </Button>
            <Button
              disabled={hasFileLoading || loadingImages > 0}
              loading={saving}
              onClick={async () => {
                if (!(await handleSave())) {
                  return;
                }

                fetchTasks();

                if (isCreateTask) {
                  onClose();
                }
              }}
              type="primary"
            >
              Save
            </Button>
          </div>
        ) : undefined
      }
    >
      <FeedbackModal
        loading={saving || loading}
        onClose={() => {
          setState((prevState: any) => ({
            ...prevState,
            showFeedbackModal: false,
            approval_status: undefined,
          }));
          form.setFieldValue('status', data?.status);
        }}
        visible={state?.showFeedbackModal}
        onConfirm={async (values: any) => {
          values.approval_status = state?.approval_status;
          if (!(await handleSave(values))) {
            return;
          }

          message.success('Updated task status.');
          fetchTasks();
          onClose();
        }}
      />

      {loading ? (
        <Skeleton />
      ) : (isCreateTask || state.data) && !axiosError ? (
        <>
          {data?.status === 'waiting_approval' && (
            <StatusActions
              selected={state?.approval_status}
              onChange={async (value: any) => {
                if (saving || loading) {
                  return;
                }

                const showFeedbackModal = value !== 'approved';

                setState((prevState: any) => ({
                  ...prevState,
                  approval_status: value,
                  showFeedbackModal,
                }));
                if (!showFeedbackModal) {
                  if (!(await handleSave({ approval_status: 'approved' }))) {
                    return;
                  }

                  message.success('Task approved.');
                  fetchTasks();
                  onClose();
                }
              }}
            />
          )}

          {!isCreateTask && data?.status === 'pending' && (
            <Button
              className="btn-green-outline btn-icon-left-md mb-3 !h-auto !whitespace-normal md:!text-left"
              icon={<CheckOutlined className="text-lg" />}
              disabled={saving || loading}
              size="large"
              block
              onClick={async () => {
                const newStatus = 'completed';

                setState((prevState: any) => ({
                  ...prevState,
                  status: newStatus,
                }));

                if (!(await handleSave({ status: newStatus }))) {
                  return;
                }

                message.success('Task completed');

                fetchTasks();

                onClose();
              }}
            >
              Click here to complete this task
            </Button>
          )}

          <Form
            className="default-form task-form"
            form={form}
            size={isCreateTask ? 'large' : 'small'}
            colon={false}
            labelCol={{ style: { textAlign: 'left', flexBasis: '90px' } }}
          >
            <Form.Item
              name="title"
              label={isCreateTask ? '' : 'Task name'}
              rules={
                isCreateTask
                  ? [{ required: true, message: 'Please enter the task name' }]
                  : undefined
              }
            >
              {isCreateTask ? (
                <Input placeholder="Give your task a name" bordered={false} />
              ) : (
                <span>{data?.title}</span>
              )}
            </Form.Item>

            {!isCreateTask && (
              <>
                <Form.Item name="status" label="Status">
                  <TagDropdown
                    trigger="click"
                    render={(value: any) => <StatusTag value={value.value} />}
                    initialValue={data?.status}
                    items={optionsStatus}
                    dropdown={
                      false /*saving || loading || optionsStatus.length <= 1*/
                    }
                    size="md"
                    onChange={async (status: string) => {
                      if (isCreateTask) return;

                      if (data?.approval_target) {
                        const showFeedbackModal = status !== 'approved';

                        setState((prevState: any) => ({
                          ...prevState,
                          approval_status: status,
                          showFeedbackModal,
                        }));

                        if (showFeedbackModal) {
                          return;
                        }

                        await handleSave({ approval_status: status });
                      } else {
                        setState((prevState: any) => ({
                          ...prevState,
                          status,
                        }));

                        await handleSave({ status });
                      }

                      if (status === 'completed') {
                        message.success('Task completed.');
                      } else {
                        message.success('Task approved.');
                      }

                      fetchTasks();
                      onClose();
                    }}
                  />
                </Form.Item>

                <Form.Item label="Assignee">
                  {responsible ? (
                    <UserAvatar size="small" user={responsible} />
                  ) : (
                    <span>Waiting to be assigned</span>
                  )}
                </Form.Item>

                {followers.length ? (
                  <Form.Item label="Followers">
                    <Avatar.Group>
                      {followers.map((item) => (
                        <UserAvatar key={item.uuid} size="small" user={item} />
                      ))}
                    </Avatar.Group>
                  </Form.Item>
                ) : undefined}

                {(data?.start_date || data?.end_date) && (
                  <Form.Item label="Due date">
                    <div className="flex items-center gap-2">
                      {data.start_date && (
                        <span>{parseDateToUSFormat(data.start_date)}</span>
                      )}

                      {data.start_date && data.end_date && (
                        <SwapRightOutlined />
                      )}

                      {data.end_date && (
                        <span>{parseDateToUSFormat(data.end_date)}</span>
                      )}

                      <IoCalendarClearOutline />
                    </div>
                  </Form.Item>
                )}
              </>
            )}

            <Form.Item name="description">
              <RichTextEditorForm
                key={open}
                ref={editorRef}
                scrollingContainer=".task-drawer .ant-drawer-body"
                name="description"
                placeholder="Describe in detail what your requirements and needs are."
                readonly={!isCreateTask}
                includeToolbar={isCreateTask}
                showMoreButton={!isCreateTask}
                collaborativeEditor={{
                  defaultValue: data?.description_delta,
                }}
                onImageUploader={(upload) => {
                  setUploads((prev) => [...prev, upload]);
                }}
                onLoadingImage={setLoadingImages}
                onImageModalChanged={(open) => setEnableDrawerKeyboard(!open)}
              />
            </Form.Item>
          </Form>

          <UploadDragger
            uploads={uploads}
            setLoading={setHasFileLoading}
            setUploads={setUploads}
            showDownloadIcon={true}
            showRemoveIcon={isCreateTask}
            showDownloadAll={!isCreateTask}
            onDownloadAll={downloadAllAssets}
            onRequest={
              isCreateTask
                ? undefined
                : async ({ uuid }) => {
                    await handleSave({ uploads: [uuid] });

                    return true;
                  }
            }
            onRemove={(upload) => {
              form.setFieldValue(
                'description',
                removeImage(
                  form.getFieldValue('description'),
                  upload.response?.url || ''
                )
              );
            }}
          />

          {!isCreateTask && (
            <Comment
              url={`${apiRoutes.PROJECT_TASKS}/${data?.uuid}/comments`}
              scrollClassName="max-h-[calc(80vh-200px)] mt-4"
              projectUuid={data?.project?.uuid ?? ''}
              onScrollIntoView={() => {
                const drawerBody = document.querySelector(
                  '.task-drawer .ant-drawer-body'
                );
                drawerBody?.scrollTo(0, drawerBody?.scrollHeight);
              }}
              onFinishSubmit={(_, images) => {
                setUploads((old) => [...old, ...images]);
              }}
              onFilesDelete={(listUuid) => {
                setUploads((old) =>
                  old.filter(
                    (item) =>
                      !listUuid.includes(item.response?.uuid ?? item.uid)
                  )
                );
              }}
            />
          )}
        </>
      ) : undefined}

      {axiosError ? <AxiosErrorResult error={axiosError} /> : undefined}
    </Drawer>
  );
};

export default TaskDrawer;
