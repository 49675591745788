import React, { Suspense } from 'react';
import { Spin } from 'antd';
import {
  createBrowserRouter,
  RouterProvider,
  ScrollRestoration,
} from 'react-router-dom';
import AuthenticationRoute from 'src/components/AuthenticationRoute';
import ErrorBoundaryRoute from 'src/components/ErrorBoundaryRoute';
import ReCaptchaProvider from 'src/components/ReCaptchaProvider';
import ErrorPage from 'src/404';
import Campaign from 'src/screens/campaigns';
import RedirectTaskPage from 'src/screens/dashboard/RedirectTaskPage';
import Pending from 'src/screens/pending';
import Login from 'src/screens/login';
import Dashboard from 'src/screens/dashboard';
import ResetScreen from 'src/screens/reset';
import Home from 'src/screens/home';
import ClientResource from 'src/screens/client-resources';
import Register from 'src/screens/register';
import SubmitForm from 'src/screens/subbmit-form';
import BookingRoute from 'src/screens/booking/BookingRoute';
import FormRequestPage from 'src/screens/request/FormRequestPage';
import RequestPage from 'src/screens/request';
import CompanyProfile from 'src/screens/company/CompanyProfile';
import RetainerSummary from 'src/screens/retainer-summary';
import ProjectAssets from 'src/screens/project-assets';
import RedirectProjectPage from 'src/screens/dashboard/RedirectProjectPage';
import Contacts from 'src/screens/contacts';
import Inbox from 'src/screens/inbox';

const router = createBrowserRouter([
  {
    element: <ErrorBoundaryRoute />,
    children: [
      {
        element: <AuthenticationRoute isAuth />,
        children: [
          {
            path: '/dashboard',
            element: (
              <>
                <ScrollRestoration />
                <Dashboard />
              </>
            ),
          },
          {
            path: '/pending',
            element: (
              <>
                <ScrollRestoration />
                <Pending />
              </>
            ),
          },
          {
            path: '/submit/:uuid',
            element: <SubmitForm />,
          },
          {
            path: '/projects/:uuid',
            element: <RedirectProjectPage />,
          },
          {
            path: '/projects/:uuid/tasks',
            element: <RedirectTaskPage />,
          },
          {
            path: '/projects/:uuid/activity-log',
            element: <RedirectTaskPage />,
          },
          {
            path: '/tasks',
            element: <RedirectTaskPage />,
          },
          {
            path: '/report',
            element: (
              <>
                <ScrollRestoration />
                <Campaign />
              </>
            ),
          },
          {
            path: '/company/profile',
            element: (
              <>
                <ScrollRestoration />
                <CompanyProfile />
              </>
            ),
          },
          {
            path: '/client-resources',
            element: (
              <>
                <ScrollRestoration />
                <ClientResource />
              </>
            ),
          },
          {
            path: '/retainer-summary',
            element: (
              <>
                <ScrollRestoration />
                <RetainerSummary />
              </>
            ),
          },
          {
            path: '/project-assets',
            element: (
              <>
                <ScrollRestoration />
                <ProjectAssets />
              </>
            ),
          },
          {
            path: '/contacts',
            element: (
              <>
                <ScrollRestoration />
                <Contacts />
              </>
            ),
          },
          {
            path: '/inbox',
            element: (
              <>
                <ScrollRestoration />
                <Inbox />
              </>
            ),
          },
          {
            path: '/request',
            element: (
              <>
                <ScrollRestoration />
                <RequestPage />
              </>
            ),
          },
          {
            path: '/request/:id',
            element: (
              <ReCaptchaProvider>
                <ScrollRestoration />
                <FormRequestPage />
              </ReCaptchaProvider>
            ),
          },
        ],
      },
      {
        element: <AuthenticationRoute isAuth={false} />,
        children: [
          {
            path: '/login',
            element: <Login />,
          },
          {
            path: '/register',
            element: <Register />,
          },
        ],
      },
      {
        element: <AuthenticationRoute isAuth={null} layoutPublic />,
        children: [
          {
            path: '/',
            element: (
              <>
                <ScrollRestoration />
                <Home />
              </>
            ),
          },
        ],
      },
      {
        element: <AuthenticationRoute isAuth={null} noLayout />,
        children: [
          {
            path: '/reset',
            element: (
              <>
                <ScrollRestoration />
                <ResetScreen />
              </>
            ),
          },
        ],
      },
      {
        element: <AuthenticationRoute isAuth={null} />,
        children: [
          {
            path: '/booking',
            element: <BookingRoute />,
          },
          {
            path: '*',
            element: (
              <>
                <ScrollRestoration />
                <ErrorPage />
              </>
            ),
          },
        ],
      },
    ],
  },
]);

const Navigation: React.FC = () => {
  return (
    <Suspense fallback={<Spin />}>
      <RouterProvider router={router} />
    </Suspense>
  );
};

export default Navigation;
