import { useState, ReactNode } from 'react';
import { Button, Drawer } from 'antd';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { TbX, TbMenu } from 'react-icons/tb';
import LionshareSVGBlue from 'src/icons/LionshareSvg-blue';
import { RootState } from 'src/store';

interface LayoutPublicProps {
  children: ReactNode;
}

const LayoutPublic = ({ children }: LayoutPublicProps) => {
  const navigate = useNavigate();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const authToken = useSelector(
    (globalState: RootState) => globalState.auth.token
  );

  const navigation = [
    { name: 'About', href: '/#about' },
    { name: 'Features', href: '/#features' },
  ];

  return (
    <div className="bg-white">
      <header className="absolute inset-x-0 top-0 z-50">
        <nav
          className="mx-auto flex max-w-6xl items-center justify-between p-6 lg:px-8"
          aria-label="Global"
        >
          <div className="flex lg:flex-1">
            <Link to="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <div className="max-w-[120px]">
                <LionshareSVGBlue />
              </div>
            </Link>
          </div>

          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <TbMenu className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>

          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <Link
                key={item.name}
                to={item.href}
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                {item.name}
              </Link>
            ))}
          </div>

          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            <Link
              to={authToken ? '/dashboard' : '/login'}
              className="rounded-full bg-[#0044CC] px-6 py-2 text-sm font-semibold leading-6 text-white"
            >
              {authToken ? 'Go to Dashboard' : 'Access Now'}{' '}
              <span aria-hidden="true">&rarr;</span>
            </Link>
          </div>
        </nav>

        <div>
          <Drawer
            placement="right"
            onClose={() => setMobileMenuOpen(false)}
            open={mobileMenuOpen}
            width={600}
            closable={false}
            destroyOnClose
          >
            <div className="mb-4 flex items-center justify-between">
              <button
                type="button"
                className="-m-1.5 p-1.5"
                onClick={() => {
                  setMobileMenuOpen(false);
                  navigate('/');
                }}
              >
                <span className="sr-only">Your Company</span>
                <div className="max-w-[120px]">
                  <LionshareSVGBlue />
                </div>
              </button>

              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <TbX className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>

            <div className="divide-y divide-gray-500/10">
              <div className="-mx-3 space-y-2 py-3 ">
                {navigation.map((item) => (
                  <Button
                    key={item.name}
                    type="link"
                    size="large"
                    className="!block !font-semibold"
                    onClick={() => {
                      setMobileMenuOpen(false);
                      setTimeout(() => {
                        navigate(item.href);
                      }, 400);
                    }}
                  >
                    {item.name}
                  </Button>
                ))}
              </div>

              <div className="py-3">
                <Link
                  to={authToken ? '/dashboard' : '/login'}
                  className="-mx-3 block rounded-md px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  {authToken ? 'Go to Dashboard' : 'Access Now'}
                </Link>
              </div>
            </div>
          </Drawer>
        </div>
      </header>
      {children}
    </div>
  );
};

export default LayoutPublic;
